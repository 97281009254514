import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

// queryClient for react-query
// https://react-query.tanstack.com/quick-start
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

interface Props {
  children: React.ReactNode;
}

const DefaultQueryClientProvider = (props: Props) => (
  <QueryClientProvider client={queryClient}>
    {props.children}
    <ReactQueryDevtools />
  </QueryClientProvider>
);

export default DefaultQueryClientProvider;
